.line {
    display: block;
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        border-left: 2px dashed var(--text-color);
        left: calc(50% - 1px);
        top: 0;
        height: 100%;
        z-index: 0;
    }
}

.status {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 100%;
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: center;

    &.todo {
        background-color: var(--darker-grey-color);
        background-image: none;
    }
    
    &.success {
        background-color: var(--green-color);
        background-image: url('/Assets/success.svg');
    }
    
    &.error {
        background-color: var(--red-color);
        background-image: url('/Assets/error.svg');
    }
}

