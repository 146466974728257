.wrapper {

    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.2s ease;

    :global(.MuiGrid-item) {
        padding: 0;
    }

    .container {
        width: 100%;
        max-width: 800px;
        max-height: calc(100vh - 130px);
        margin-top: 60px;
        background-color: white;
        display: block;
        top: 0;
        left: 0;
        z-index: 99;
        border-radius: 20px;
        padding: 20px;
        position: relative;
        overflow: scroll;
        z-index: -1;
    }
}

.overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    display: block;
    top: 0;
    left: 0;
    z-index: 99999;
    opacity: 0;
    transition: all 0.2s ease;
}

.button {
    width: 40px;
    height: 40px;
    background-image: url(../../Assets/dark_x.svg);
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    right: 10px;
    z-index: 999;
    top: 10px;
    border-radius: 50px;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
        background-color: var(--grey-color);
    }

}

.show {
    opacity: 1;
}