.mapContainer {
    height: calc(100vh - 70px);
}

.container {
    position: relative;
    width: 100%;
    z-index: 9;

    input {
        width: 100%;
        height: 50px;
        padding: 15px !important;
        background-color: var(--light-grey-color);
        border-radius: 15px;
        border: 0;
        outline: none;
        font-family: inherit;
        font-size: inherit;
        color: inherit;
    }

    :global(.mapboxgl-ctrl-geocoder--icon),
    :global(.mapboxgl-ctrl-geocoder--button) {
        display: none !important;
    }

    ul {
        position: absolute;
        background-color: white;
        display: block;
        list-style: none;
        margin: 0;
        padding: 10px;
        width: 100%;
        overflow: scroll;
        box-sizing: border-box;

        :global(.mapboxgl-ctrl-geocoder--powered-by) {
            display: none !important;
        }

        li {
            margin: 5px 0px;

            a {
                font-size: 13px;
                padding: 5px;
                color: var(--primary-color);
                cursor: pointer;
                display: block;
                border-radius: 10px;

                &:hover {
                    background: var(--light-grey-color);
                    color: var(--primary-color);
                }
            }
        }
    }
}

.predictions {
    width: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    border-radius: 15px;
    padding: 5px 10px;
    border: 2px solid #F2F3F5;
    background-color: white;
    z-index: 999;
}