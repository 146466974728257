.react-calendar {
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-radius: 15px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    position: absolute;
    z-index: 999;
}

.react-calendar__tile:disabled {
    background-color: transparent;
    opacity: 0.6;
}

.react-calendar__navigation {
    padding: 0 8px;

    button {
        color: var(--primary-color);
        min-width: 44px;
        background: none;
        font-size: 16px;
        margin-top: 8px;

        &.react-calendar__navigation__label {
            pointer-events: none;
        }

        &:enabled {

            &:hover,
            &:focus {
                background-color: var(--light-blue);
                border-radius: 10px;
            }
        }
    }
}

.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}

abbr[title] {
    text-decoration: none;
}

/* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
   } */

.react-calendar__tile {
    position: relative;

    &:disabled {
        cursor: auto;
        pointer-events: none;
    }
}

.react-calendar__tile .hoverDetectOverlay {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.react-calendar__tile.weekly {
    &.hovered {
        background: #f8f8fa;
        color: var(--primary-color);
        border-radius: 6px;

        &.weekly {
            border-radius: 0 !important;
        }

        &.firstOfWeek {
            border-top-left-radius: 6px !important;
            border-bottom-left-radius: 6px !important;
        }

        &.lastOfWeek {
            border-top-right-radius: 6px !important;
            border-bottom-right-radius: 6px !important;
        }

        &:enabled:hover,
        &:enabled:focus {
            background: #f8f8fa;
            color: var(--primary-color);
        }
    }
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: var(--primary-color);
}

// .react-calendar__tile.hovered {
//     background: #f8f8fa;
//     color: var(--primary-color);
//     border-radius: 6px;

//     &.weekly {
//         border-radius: 0 !important;
//     }

//     &.firstOfWeek {
//         border-top-left-radius: 6px !important;
//         border-bottom-left-radius: 6px !important;
//     }

//     &.lastOfWeek {
//         border-top-right-radius: 6px !important;
//         border-bottom-right-radius: 6px !important;
//     }

//     &:enabled:hover,
//     &:enabled:focus {
//         background: #f8f8fa;
//         color: var(--primary-color);
//     }
// }

.react-calendar__tile.weekly {
    &.selected {

        &:enabled:hover,
        &:enabled:focus {
            background: var(--primary-color) !important;
            color: white;
        }
    }
}

.react-calendar__tile.weekly:enabled:hover,
.react-calendar__tile.weekly:enabled:focus {
    border-radius: 0px;
}

.react-calendar__tile--now {
    background: var(--light-blue);
    border-radius: 6px;
    font-weight: bold;
    color: var(--primary-color);
    ;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    // background: var(--light-blue);
    border-radius: 6px;
    font-weight: bold;
    color: var(--primary-color);
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
}

.react-calendar__tile--active,
.react-calendar__tile.selected {
    background: var(--primary-color) !important;
    // border-radius: 6px;
    font-weight: bold;
    color: white !important;

    &.weekly {
        border-radius: 0px !important;

        &.firstOfWeek {
            border-top-left-radius: 6px !important;
            border-bottom-left-radius: 6px !important;
        }

        &.lastOfWeek {
            border-top-right-radius: 6px !important;
            border-bottom-right-radius: 6px !important;
        }
    }
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: var(--primary-color);
    color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fa;
}

.react-calendar__tile--range {
    background: #f8f8fa;
    color: var(--primary-color);
    ;
    border-radius: 0;
}

.react-calendar__tile--rangeStart {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: var(--primary-color);
    ;
    color: white;
}

.react-calendar__tile--rangeEnd {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    background: var(--primary-color);
    ;
    color: white;
}