.circle {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: var(--light-blue);
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.center {
    align-items: center;
}

.header {
    position: sticky;
    top: 0px;
    left: 0;
    padding-top: 1px;
    z-index: 9999;
    background-color: white;
}