.select {
    width: auto;
    margin-left: 20px;

    &.colored-blue {
        :global(.MuiInputBase-root) {
            background-color: var(--primary-color) !important;
        }
    }

    &.colored-green {
        :global(.MuiInputBase-root) {
            background-color: var(--green-color) !important;
        }
    }

    &.colored-yellow {
        :global(.MuiInputBase-root) {
            background-color: var(--yellow-color) !important;
        }
    }

    &.colored-red {
        :global(.MuiInputBase-root) {
            background-color: var(--red-color) !important;
        }
    }

    &:hover {
        :global(.MuiInputBase-root) {
            padding-right: 12px !important;

            svg {
                display: block;
            }
        }
    }

    :global(.MuiInputBase-root) {
        margin-bottom: 0 !important;
        height: auto !important;
        width: auto !important;
        padding: 0 !important;
        border-radius: 5px !important;
        transition: 0.2s ease all;

        svg {
            display: none;
            right: 0px;
        }

        :global(.MuiSelect-select) {
            padding: 0px 10px !important;
            font-size: 12px !important;
        }
    }


}