.mapContainer {
    height: calc(100vh - 70px);
}

.dragDropContainer {
    width: calc(100% - 60px) !important;
    height: 300px !important;
    max-width: calc(100% - 60px) !important;
    box-sizing: border-box !important;
    padding: 0 50px !important;
    margin: 30px !important;
}

.createTableScrollContainer {
    width: 100% !important;
    margin: 30px 0 !important;
    overflow: scroll;
}

.checkTable {

    border-collapse: collapse; 

    thead {
        position: sticky;

        td {
            font-weight: bold;
            background-color: #EFEFEF;
        }
    }

    tbody {
        tr {
            td {
                white-space: nowrap;
                padding: 10px;
            }

            &:nth-child(even) {
                background-color: #EFEFEF;
            }

        }
    }

    tr {
        td {
            white-space: nowrap;
            padding: 5px;
        }
    }
}