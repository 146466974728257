.listContainer {
    background-color: white;
    border: 2px solid var(--darker-grey-color);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 15px;
    padding: 15px 15px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    // background-image: url('../../Assets/three_dots.png');
    // background-position: center right 20px;
    // background-repeat: no-repeat;
    // background-size: 4px;
    padding-right: 30px;
    position: relative;

    .inner {
        display: block;
    }

    .titleContainer {
        width: 100%;

        &.inline {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }

    .sideImageContainer {
        height: 20px;
        width: 50px;
        top: 0;
        right: -40px;

        .sideImage {
            height: 100%;
            width: auto;
            position: absolute;
            top: 0;
            right: 0;
            margin-right: 0;
            border-top-right-radius: 13px;
            border-bottom-right-radius: 13px;
            overflow: hidden;
        }
    }

    h4 {
        margin: 0;
    }

    img {
        width: 25px;
        margin-right: 15px;
    }

    &.selected,
    &:hover {
        border-color: var(--primary-color);
    }

    .badge {
        display: block;
        background-color: #1D9CE5;
        border-radius: 20px;
        font-size: small;
        padding: 4px 10px;
        text-transform: uppercase;
        margin-left: 10px;

        &.green {
            color: #5CB969;
            background-color: #C7EFCD;
        }

        &.blue {
            color: #1D9CE5;
            background-color: #A3D7F5;
        }

        &.red {
            color: #D53030;
            background-color: #F5C3C3;
        }

        &.yellow {
            color: #D5AF30;
            background-color: #F5F0AB;
        }
    }

    .detail {
        color: var(--red-color);
        font-size: small;
    }
}