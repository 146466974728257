.wrapper {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    box-shadow: 0px 10px 20px rgba(26, 119, 189, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 99999;

    .container {
        width: 100%;
        max-width: 1400px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        img {
            height: 50px;
            padding: 10px;
        }

        nav {
            a {
                padding: 5px;
                margin: 0 10px;
                font-weight: 600;
                text-decoration: none;
                color: var(--text-color);
                display: inline-flex;
                align-items: center;
                position: relative;

                @media screen and (max-width: 1200px) {
                    span {
                        display: none;
                    }
                }

                span {
                    position: relative;

                    &::after {
                        display: block;
                        height: 2px;
                        position: absolute;
                        width: 0;
                        bottom: 0;
                        content: '';
                        transition: 0.2s ease all;
                        background: linear-gradient(to left,
                                #2D93E0 0%,
                                #01ACED 100%) left bottom #777 no-repeat;
                        background-size: 100% 5px;
                    }
                }

                &:hover,
                &.current {
                    color: var(--primary-color);

                    svg {
                        fill: url('#linear-gradient') !important;
                    }


                }

                &:hover {
                    span {
                        &::after {
                            width: 100%;
                        }
                    }
                }

                svg {
                    height: 20px;
                    margin-right: 4px;
                    fill: var(--text-color);
                }
            }

            .subnavWrapper {
                padding: 0px;
                margin: 0 0px;
                display: inline-flex;
                position: relative;

                &:hover {
                    >a {
                        color: var(--primary-color);

                        svg {
                            fill: url('#linear-gradient') !important;
                        }

                        span {
                            &::after {
                                width: 100%;
                            }
                        }
                    }

                    .subnav {
                        display: flex;
                    }
                }

                a {
                    display: flex;
                }

                .subnav {
                    // border-top: 2px solid var(--light-grey-color);
                    display: none;
                    flex-direction: column;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: max-content;
                    list-style: none;
                    margin: 0;
                    padding: 10px 0;
                    background-color: white;
                    border-bottom-left-radius: 15px;
                    border-bottom-right-radius: 15px;
                }
            }

        }

        @media screen and (max-width: 1300px) {
            :global(.search) {
                width: 200px;
            }
        }

        .verticalLine {
            display: block;
            height: 24px;
            width: 2px;
            background: var(--light-grey-color);
        }

        .notifications {
            display: block;
            height: 25px;
            width: 25px;
            background-image: url('../../Assets/navigationIcons/notifications.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            position: relative;
            cursor: pointer;

            .badge {
                width: 18px;
                height: 18px;
                background-color: var(--red-color);
                color: white;
                position: absolute;
                top: -5px;
                right: -5px;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
                border-radius: 100%;
                font-weight: 600;
                font-size: 12px;
            }
        }

        .profileContainer {
            transition: all 0.2s ease;
            position: relative;

            nav {
                display: none;
                width: 100%;
                min-width: 300px;
                position: absolute;
                right: 0px;
                top: calc(100% - 1px);
                height: 300px;
                background-color: var(--light-grey-color);
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                border-top-left-radius: 15px;
                padding-top: 10px;
            }

            &.open {
                .inner {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    background-color: var(--light-grey-color);

                    .arrow {
                        transform: rotate(180deg);
                    }
                }

                nav {
                    display: block;

                    a {
                        width: 100%;
                    }
                }
            }

            .inner {
                border-radius: 15px;
                display: flex;
                width: 200px;
                align-items: center;
                cursor: pointer;
                padding: 10px;

                @media screen and (max-width: 1200px) {
                    width: 70px;

                    .contentContainer {
                        display: none;
                    }
                }

                &:hover,
                &.open {
                    background-color: var(--light-grey-color);
                }

                .icon {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    background-color: var(--light-blue);
                    color: var(--primary-color);
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    margin-right: 15px;
                }

                .contentContainer {
                    width: calc(100% - 67px);
                    font-weight: 500;

                    .name {
                        color: #121212;
                    }

                    .positions {
                        color: #727272;
                        font-size: 10px;
                    }
                }

                .arrow {
                    width: 12px;
                    height: 10px;
                    background-image: url('../../Assets/navigationIcons/arrow_down.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    transition: all 0.2s ease;
                }
            }
        }
    }
}


.companyList {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;

    .container {
        padding: 10px;
        box-sizing: border-box;
        border-radius: 15px;
        cursor: pointer;

        &.current {
            color: var(--primary-color);
            pointer-events: none;
        }

        &:hover {
            background-color: var(--darker-grey-color);
        }

        .icon {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            background-color: var(--light-blue);
            color: var(--primary-color);
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            margin-right: 15px;
        }

        .rightContainer {
            width: calc(100% - 60px);
            font-weight: 500;

            .name {
                color: #121212;
            }

            .positions {
                color: #727272;
                font-size: 10px;
            }
        }
    }
}

.searchContainer {
    display: inline-block;
    position: relative;
    height: 54px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    &.hasResults {
        :global(.MuiInputBase-root) {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }


    .results {
        display: inline-block;
        border-left: 2px solid white;
        border-right: 2px solid white;
        border-bottom: 2px solid white;
        border-bottom-left-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
        top: 100%;
        margin-top: -2px;
        left: 0;
        width: 100%;
        background-color: var(--light-grey-color);
        max-height: 90vh;
        overflow: scroll;
        position: absolute;
        padding: 10px;

        .result {
            display: block;
            width: 100%;
            padding: 10px;
            margin-top: 5px;
            margin-bottom: 5px;
            font-size: 80%;
            cursor: pointer;
            transition: 0.2s ease all;
            position: relative;
            border: 1px solid var(--darker-grey-color);
            border-radius: 15px;

            &:hover {
                background-color: white;
                border: 1px solid transparent;
            }

            .resultRow {
                margin-top: 5px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

        }
    }
}