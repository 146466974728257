.paginationContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .seperator {
        width: 30px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        font-size: 20px;
        line-height: 0px;
        margin-bottom: 12px;
    }

    .button {
        width: 30px;
        height: 30px;
        background-color: #EFEFEF;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 10px;
        margin: 0 5px;

        &.current {
            background-color: var(--primary-color);
            color: white;
        }

    }

}