.container {
    width: fit-content;
    min-width: 21px;
    display: inline-flex;
    margin: 5px;
    border-radius: 10px;
    box-sizing: border-box;
    position: relative;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    padding: 10px 17px;

    &.small {
        padding: 2px 6px;
        border-radius: 20px;
        font-weight: bold;
        font-size: 11px;
    }

    .icon {
        width: 10px;
        height: 10px;
        object-fit: contain;
        object-position: center;
        margin-right: 3px;
    }

    &.green,
    &.success,
    &.active,
    &.accepted {
        color: #5CB969;
        background-color: #C7EFCD;
    }

    &.blue {
        color: #1D9CE5;
        background-color: #A3D7F5;
    }

    &.red,
    &.error,
    &.rejected,
    &.problem,
    &.failed,
    &.too_late {
        color: #D53030;
        background-color: #F5C3C3;
    }

    &.yellow,
    &.pending {
        color: #D5AF30;
        background-color: #F5F0AB;
    }

    &.grey {
        color: var(--text-color);
        background-color: var(--darker-grey-color);
    }


    .labelContainer {
        display: flex;
        align-items: center;
    }

    .label {
        display: block;
        transition: all 0.3s ease;

        &.useStatus {
            padding-right: 40px;
        }
    }

}