.proofCard {
    &.success {
        border: 0;
        border-color: var(--light-green-color);
        background-color: var(--light-green-color);
    }

    &.failed {
        border: 0;
        border-color: var(--red-color);
        background-color: var(--light-red-color);
    }

    img {
        width: 100%;
        background-color: white;
        border-radius: 15px;
        overflow: hidden;
        border: 0;
    }
}