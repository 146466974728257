.messagesWrapper {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 999;
}

.wrapper {
    width: 100%;
    max-width: 500px;
    border-radius: 15px;
    background-color: white;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
    position: relative;
    overflow: hidden;
    margin-top: 20px;
    transition: all 0.2s ease;

    &.success {
        .container {
            .title {
                color: var(--green-color);
            }
        }

        .counter {
            background-color: var(--green-color);
        }
    }

    &.error {
        .container {
            .title {
                color: var(--red-color);
            }
        }

        .counter {
            background-color: var(--red-color);
        }
    }

    .counter {
        width: 20px;
        height: 4px;
        position: absolute;
        top: 0;
        left: -5px;
        border-radius: 4px;
    }

    .container {
        display: flex;
        align-items: center;

        h4 {
            padding: 0;
            margin: 0;
        }

        .leftContainer {
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 30px;
            }
        }

        .rightContainer {
            width: calc(100% - 80px);
            display: block;
            padding: 14px;
            padding-left: 0;
        }
    }
}