.detailContainer {
    .icon {
        width: 100px;
        height: 100px;
        font-size: 30px;
        border-radius: 100%;
        background-color: var(--light-blue);
        color: var(--primary-color);
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
}