.groupContainer {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    padding: 20px;
    position: relative;
    background-color: white;

    .combinatorWrapper {
        position: absolute;
        box-sizing: border-box;
        left: 30px;
        border-top: 2px solid;
        border-left: 2px solid;
        border-bottom: 2px solid;
        border-color: #F8D9AE;
        width: 50px;
        top: 80px;
        height: calc(100% - 202px);
        margin-top: -10px;
        display: block;
        z-index: 0;

        &.and {
            border-color: var(--light-blue);

            .combinatorLabel {
                background-color: var(--light-blue);
            }
        }

        .combinatorLabel {
            position: absolute;
            box-sizing: border-box;
            left: 0px;
            transform: translateX(-50%);
            padding: 4px 10px;
            background-color: #F8D9AE;
            top: 50%;
            margin-top: -10px;
            display: block;
            border-radius: 20px;
            cursor: pointer;
        }

    }

    .contentContainer {
        padding-left: 70px;
    }

    .buttonContainer {}
}

.element {
    position: relative;
}

.deleteButton {
    position: absolute;
    top: 0;
}