.container {
    width: 100%;
    border: 2px solid #F4F6F8;
    border-radius: 15px;
    transition: 0.2s ease all;
    cursor: pointer;
    padding: 10px 20px;
    background-image: url('../../Assets/formIcons/calendar.svg');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center left 20px;
    padding-left: 55px;
    position: relative;

    :global(.react-calendar) {
        left: 0;
        width: 100%;
    }

    &:hover {
        background-color: #F4F6F8;
    }
}

.relative {
    position: relative !important;
    width: 100% !important;
}