.container {
    position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    padding: 20px;
    background-color: white;
    max-height: calc(100% - 20px);
    overflow: scroll;
    width: 400px;
    display: block;
    z-index: 9999;
    padding-top: 0px;

    &.level2 {
        left: 420px;
    }

    .closeButton {
        width: 40px;
        height: 40px;
        background-image: url(../../Assets/dark_x.svg);
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 9999999999999999;
        border-radius: 50px;
        transition: all 0.2s ease;
        cursor: pointer;

        &:hover {
            background-color: var(--grey-color);
        }

    }
}