.button {
    vertical-align: top;

    &.delete {

        &.tertiary {

            &:hover,
            &.selected {
                background: #F5C3C3 !important;
            }

            .label {
                color: var(--red-color) !important;
            }
        }

        &.primary {
            background: var(--red-color) !important;
        }

        &.secondary {
            color: #D53030;
            background-color: #F5C3C3;

            &:hover,
            &.selected {
                background-color: #D53030;
                color: white;
            }
        }

    }

    &.primary {
        width: calc(100%);
        background: linear-gradient(#2D93E0, #01ACED);
        background-color: linear-gradient(#2D93E0, #01ACED);
        display: flex;
        padding: 20px;
        color: white;
        text-align: center;
        border-radius: 15px;
        cursor: pointer;
        box-sizing: border-box;
        position: relative;
        justify-content: center;
        transition: all 0.2s ease;

        &.disabled {
            pointer-events: none;
            background: #9CB6C9 !important;
        }

        &.smallWidth {
            width: fit-content;
            display: inline-flex;
        }

        &:hover,
        &.hovered {
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
        }

        &.success .successContainer {
            display: block;
        }

        &.loading,
        &.success,
        &.error {
            pointer-events: none;
        }

        &.error {
            background-color: #E06D6D !important;
            background: #E06D6D !important;
        }

        &.loading {
            opacity: 0.7;
        }
    }

    &.secondary {
        width: fit-content;
        background-color: #A3D7F5;
        display: inline-flex;
        margin: 5px;
        padding: 5px 10px;
        color: #1D9CE5;
        border-radius: 10px;
        cursor: pointer;
        box-sizing: border-box;
        position: relative;
        justify-content: center;
        font-size: 13px;

        &.disabled {
            pointer-events: none;
            opacity: 0.4;
        }

        &.large {
            padding: 10px 17px;
        }

        &:hover,
        &.hovered {
            background-color: #1D9CE5;
            color: white;
        }

        &.green {
            color: #2ECC40;
            background-color: #E8FAEA;

            &:hover,
            &.hovered {
                color: white;
                background-color: #2ECC40;
            }

        }

        &.loading {
            .label {
                padding-right: 50px;
            }
        }
    }

    &.tertiary {
        color: var(--primary-color);
        text-align: center;
        cursor: pointer;
        position: relative;
        display: inline-block;
        align-items: center;
        background-color: transparent;
        width: -webkit-fill-available;
        width: fit-content;
        transition: all 0.3s ease;
        padding: 5px 10px;
        margin: 5px;
        border-radius: 10px;
        font-size: 13px;

        &.disabled {
            pointer-events: none;
            opacity: 0.4;
        }

        &.large {
            padding: 10px 17px;
        }

        &:hover,
        &.hovered {
            background-color: var(--light-blue);

            &::after {
                width: 100%;
            }
        }

        &.success {
            background-color: var(--green-color) !important;
            background: var(--green-color) !important;
            color: white !important;

            .successContainer {
                display: block;
                background-size: 14px;
                background-position: center right;
            }
        }

        &.loading,
        &.success,
        &.error {
            pointer-events: none;
        }

        &.error {
            background-color: var(--red-color) !important;
            background: var(--red-color) !important;
            color: white !important;

            .errorContainer {
                background-position: center right;
                background-size: 10px;
            }
        }

        &.loading {
            opacity: 0.7;
        }

        img {
            height: 15px;
            margin-right: 10px;
        }

        .lds-ellipsis {
            margin-left: 7px;
        }

        .lds-ellipsis div {
            position: absolute;
            top: 0px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: var(--primary-color);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
        }

    }

    &.small {
        color: var(--primary-color);
        text-align: center;
        cursor: pointer;
        position: relative;
        display: inline-block;
        align-items: center;
        background-color: transparent;
        width: -webkit-fill-available;
        width: fit-content;
        transition: all 0.3s ease;
        padding: 0;
        margin: 0;
        border-radius: 0;
        font-size: 11px;

        &.disabled {
            pointer-events: none;
            opacity: 0.4;
        }

        &:after {
            width: 0;
            position: absolute;
            content: '';
            display: block;
            height: 4px;
            background-color: var(--primary-color);
            top: calc(100% - 7px);
            left: -2px;
            opacity: 0.2;
            transition: 0.1s ease all;
        }

        &:hover,
        &.hovered {
            background-color: none;

            &:after {
                width: calc(100% + 4px);
            }
        }
    }

    input {
        visibility: hidden;
        width: 0;
        height: 0;
    }

    .labelContainer {
        display: flex;
        align-items: center;
    }

    .label {
        display: block;
        transition: all 0.3s ease;

        &.useStatus {
            padding-right: 40px;
        }
    }

    .stateContainer {
        display: block;
        width: 30px;
        height: 100%;
        position: absolute;
        right: 20px;
        top: 0px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .successContainer {
        background-image: url(../../Assets/success.svg);
        background-size: 20px;
    }

    .errorContainer {
        background-image: url(../../Assets/error.svg);
        background-size: 15px;
    }

    .loadingContainer {
        display: flex;
        align-items: center;
    }
}



/* Loading Indicator */

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 6px;
}

.lds-ellipsis div {
    position: absolute;
    top: 0px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 0px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 0px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 12px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 24px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(12px, 0);
    }
}