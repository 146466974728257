.container {
    display: flex;
    align-items: center;
    flex-direction: column;

    .logo {
        width: 50%;
        margin-top: 20%;
        margin-bottom: 20%;
    }
}